<template>
<div class="wrapper">
  <!-- 友链管理 -->
  <el-card class="card-1">
    <!-- 标题 -->
    <div class="header"><h2>友链管理</h2></div>
      <!-- 查询表单 -->
      <el-form :model="ruleForm"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div class="form-1">
          <el-form-item label="站点名称：" class="form-item">
            <el-input v-model="ruleForm.websiteName"></el-input>
          </el-form-item>
          <el-form-item label="" class="query-btn">
            <el-button type="primary" size="" @click="submitFormQuery()" >查询</el-button>
            <el-button @click="resetFormQuery('ruleForm')">重置</el-button>
            <el-button type="success" @click="showAddLinks">添加友链</el-button>
          </el-form-item>
        </div>
      </el-form>
      <!-- ======================主体表格区域==================== -->
      <div class="item-wrap" >
        <el-table :data="tableData" border style="width: 100%;" >
          <el-table-column type="index" width="40"></el-table-column>
          <el-table-column property="websiteName"  label="站点名称" width="120"></el-table-column>
          <el-table-column property="websiteDesc" label="站点描述" width="250"></el-table-column>
          <el-table-column property="websiteUrl" label="地址" width="220"></el-table-column>
          <el-table-column property="websiteAvatar" label="头像" width="270"></el-table-column>
          <el-table-column property="status" label="状态" width="80">
            <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.status"
                    active-value="1"
                    inactive-value="0"
                    @change="changeSwitch(scope.row,scope.$index)">
                  </el-switch>
            </template>
          </el-table-column>
          <el-table-column property="type" label="类型"  width="100">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.type === '友情链接' ? 'primary' : 'success'"
                disable-transitions>{{scope.row.type}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" >
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page-wrap">
          <!--handleSizeChange和handleCurrentChange页面监听方法，当页面发生变化时执行-->
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum" :page-sizes="[5, 9 , 15, 20]" :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total" class="page">
          </el-pagination>
        </div>
      </div>

    <!-- ===============================添加友链的dialog=================================== -->
    <div class="addLinks" v-show="showAddLinksDialog">
      <div class="content">
        <!-- 表单 -->
        <el-form :model="ruleFormAddLinks"   label-width="100px" class="demo-ruleForm">
          <el-form-item label="站点名称" >
            <el-input v-model="ruleFormAddLinks.websiteName"></el-input>
          </el-form-item>
          <el-form-item label="站点描述" >
            <el-input v-model="ruleFormAddLinks.description"></el-input>
          </el-form-item>
          <el-form-item label="地址" >
            <el-input v-model="ruleFormAddLinks.url"></el-input>
          </el-form-item>
          <el-form-item label="头像" >
            <el-input v-model="ruleFormAddLinks.avatar"></el-input>
          </el-form-item>
          <el-form-item label="友链类型：" >
          <el-select v-model="type_of_link" prop="cateName"  placeholder="请选择友链类型" class="cate_select">  <!--选择分类的下拉框-->
            <el-option
              v-for="item in link_options"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm()">新增</el-button>
            <el-button @click="updateForm()">修改</el-button>
            <el-button @click="cancel">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-card>
</div>
</template>


<script>
export default {
  name : 'FriendLinks' , 
  data(){
    return{
      ruleForm:{} ,  // 查询表单
      tableData: [], // 表格展示的数据
      total:0 , 
      queryInfo:{    // 查询信息实体VO类
        id:"" ,
        linkStatus:"" , 
        websiteName:"" ,   
        websiteDesc:"" , 
        websiteUrl:"" , 
        websiteAvatar:"" , 
        status:"" , 
        type:"" ,
        pageNum: 1 ,   // 设定起始页
        pageSize: 5    // 每页显示的数量
      } , 
      ruleFormAddLinks:{
        id:"" , 
        linkStatus:"" , 
        websiteName:"" , 
        description:"" , 
        url:"" , 
        avatar:"" , 
        type:""
      } , // 新增links的表单数据
      showAddLinksDialog:false ,
      type_of_link:"" ,  //  链接类型
      link_options:[{id:1 , typeName:"友情链接"} , {id:2 , typeName:"食用网站"}] ,  // 链接类型的下拉框数据 
    }
  }  , 
  methods:{
    // ==================顶部表单查询方法=====================
    // 查询方法
    submitFormQuery(){
      console.log(this.ruleForm);
      this.getLinksList() ; 
    } , 
    // 重置方法
    resetFormQuery(formName) {
      this.ruleForm = {} ; 
    } , 
    // =================新增友链方法=====================
    // 添加友链按钮，展示添加友链的表单
    showAddLinks(){
      if(this.$store.state.userInfo){
        this.getTypeOfLinks() ;   // 查询友链类型
        this.showAddLinksDialog = !this.showAddLinksDialog ;
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    // 提交新增友链信息
    submitForm(){
      this.ruleFormAddLinks.type = this.type_of_link ; 
      // console.log(this.ruleFormAddLinks);
      const _this = this ; 
      this.$axios.post('/addLinksManage' , this.ruleFormAddLinks , {
        headers:{
          "Authorization":localStorage.getItem("token")
        }
      }).then(res => {
        this.$alert('操作成功', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.ruleFormAddLinks = {} ; 
            this.showAddLinksDialog = !this.showAddLinksDialog ; 
            this.getLinksList() ; 
          }
        });
      })
    } ,
    // 返回
    cancel(){
      this.showAddLinksDialog = !this.showAddLinksDialog ; 
      this.ruleFormAddLinks = {} ; 
    } ,
    // ====================表格方法===================
    // 获取所有友链信息
    getLinksList(){
      const _this = this ; 
      this.queryInfo = this.ruleForm ; 
      this.$axios.post("/getLinksListManage"  ,this.queryInfo).then(res => {
        this.tableData = res.data.data.data ;
        this.total = res.data.data.numbers ; 
        // console.log(res);
      }) ; 
    } , 
    // 分页组件中的 页面监听方法，当显示最大数量发生变化时执行
    handleSizeChange(newSize){  
      this.queryInfo.pageSize = newSize ; 
      this.getLinksList() ;    // 发生变化后重新加载列表
    } ,
    // 分页组件中的方法，pageNum触发动作，当页码发生变化时执行
    handleCurrentChange(newPage){
      this.queryInfo.pageNum = newPage ; 
      this.getLinksList() ; 
    }  , 
    // 修改友链状态方法
    changeSwitch(row,index){
      console.log(row);
      this.ruleFormAddLinks.id = row.id ; 
      this.ruleFormAddLinks.linkStatus = row.linkStatus ; 
      this.ruleFormAddLinks.websiteName = row.websiteName ; 
      this.ruleFormAddLinks.description = row.websiteDesc ; 
      this.ruleFormAddLinks.url = row.websiteUrl ; 
      this.ruleFormAddLinks.avatar = row.websiteAvatar ; 

      let word = row.linkStatus==="1" ? "隐藏" : "显示"  ;
      if(this.$store.state.userInfo){
        this.$confirm('是否'+ word +'该友链?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post("/modifyStatusManage" , this.ruleFormAddLinks).then(res => {
            this.$message.success("操作成功") ; 
            this.getLinksList() ; 
          }) ; 
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    // 删除友链方法
    handleDelete(index, row) {
      if(this.$store.state.userInfo){
        console.log(row.websiteName);
        this.$confirm('是否删除该友链?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post("/deleteLinksManage" , row).then(res => {
            this.$message.success("删除成功") ; 
            this.getLinksList() ; 
          }) ; 
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    // 编辑友链方法
    handleEdit(index, row) {
      if(this.$store.state.userInfo){
        // console.log(row);
        this.showAddLinksDialog = !this.showAddLinksDialog ; 
        this.ruleFormAddLinks.id = row.id ; 
        this.ruleFormAddLinks.linkStatus = row.linkStatus ; 
        this.ruleFormAddLinks.websiteName = row.websiteName ; 
        this.ruleFormAddLinks.description = row.websiteDesc ; 
        this.ruleFormAddLinks.url = row.websiteUrl ; 
        this.ruleFormAddLinks.avatar = row.websiteAvatar ; 
        this.ruleFormAddLinks.type = row.type === "友情链接" ? 0 : 1 ; 
        
        // console.log(this.ruleFormAddLinks);
        this.getTypeOfLinks() ; 
        // let value = row.type === "友情链接" ? 0 : 1 ; 
        // let name = row.type ; 
        // this.link_options.push({"name":name , "value":value}) ;

        this.type_of_link = row.type ; 

      }else{
        this.$message.error("请先登录~") ; 
      }
    },
    // 修改友链
    updateForm(){
      this.$axios.post('/updateLinksManage' , this.ruleFormAddLinks , {
        headers:{
          "Authorization":localStorage.getItem("token")
        }
      }).then(res => {
        this.$alert('操作成功', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.ruleFormAddLinks = {} ; 
            this.showAddLinksDialog = !this.showAddLinksDialog ; 
            this.getLinksList() ; 
          }
        });
      })
    } , 
    // 获取友链类型方法
    getTypeOfLinks(){
      const _this = this ; 
      this.$axios.get("/getTypeOfLinks" ).then(res => {
        this.link_options = res.data.data ;
        // console.log("获取友链方法");
        // console.log(this.link_options);
      }) ; 
    } , 
  } ,
  created(){
    this.getLinksList() ; 
  }
}
</script>


<style scoped>
/* 标题 */
.header{
  font-size: 1rem;
  border-bottom: 1px solid rgb(219, 218, 218);
  box-sizing: content-box;
}
/* 查询表单 */
.demo-ruleForm .form-1{
  margin-top: 3.5rem;
  display: flex;
}
.demo-ruleForm .form-1 .form-item{
  width: 45rem;
}
/* 主体表格 */
.page{
  margin-top: .9rem;
}


/* 添加友链dialog */
.addLinks{
  position: absolute;
  top: 10rem;
  left: 33rem;
  z-index: 99;
}
.content{
  width: 83rem;
  /* height: 100vh; */
  padding-top: 10rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  padding: 10px 50px 10px 0;
  background-color: white;
  border:1px solid #ccc;
  box-shadow: .5rem 2rem 3rem rgba(0, 0, 0, .3);
}

</style>